import React from "react";
import logoImg from "../assets/img/logo.png"; 

const About = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img src={logoImg} alt="Logo" />

                  <div
                    className="main about-card-image"
                  ></div>
                </div>
                {/* End image */}
              </div>
              {/* End left */}

              <div className="right">
                <div
                  className="shane_tm_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>About Me</span>
                  <h3>Real Estate Agent &#38; Founder of Royal Realty Group based in Indianapolis, IN</h3>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                    Anthony Bellamy is a licensed Real Estate Agent and Founder of Indianapolis-based realtor Royal Realty Group. 
                    Anthony has over 20 years of experience in the real estate business and operates across the United States with a focus on Indiana and Florida.
                    Anthony has a specialty in commercial real estate and is ready to serve your commercial real estate needs.
                    Visit Royal Realty Group today to find your new home or contact Anthony directly below!
                  </p>
                </div>
                <div
                  className="shane_tm_button flex"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a href="https://www.royalrealtygroup.org/" className="rrg-btn-blue" target="_blank" rel="noreferrer">
                    Visit Royal Realty Group
                  </a>
                  <a href="#contact" className="margin-left-10">
                    Contact Anthony
                  </a>
                </div>
              </div>
              {/* End right */}
            </div>
          </div>
          {/* End container */}
        </div>
      </div>
    </>
  );
};

export default About;

import React, {useState} from "react";
import { useForm } from "react-hook-form";
import Recaptcha from "react-recaptcha";
import * as emailjs from "emailjs-com";
import { Alert, AlertTitle, CircularProgress } from "@mui/material";
import Keys from "../assets/misc/keys.js";

const Contact = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [successfulMessage, setSuccessfulMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);

  const [recaptchaLoad, setRecaptchaLoad] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [captchaResponse, setCaptchaResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaLoaded = () => {
    setRecaptchaLoad(true);
  };

  const verifiedRecaptcha = (response) => {
    if (response) {
      setCaptchaResponse(response);
      setIsVerified(true);
    }
  };

  const resetForm = () => {
    setName("");
    setMessage("");
    setEmail("");
  };

  const onSubmit = () => {
    if (recaptchaLoad && isVerified) {
      const templateParams = {
        from_name: name,
        from_email: email,
        to_name: "Anthony Bellamy",
        to_email: "antb@anthonybellamy.com",
        subject: "New Message from " + name,
        message_html: message,
        "g-recaptcha-response": captchaResponse,
      };

      setFailedMessage(false);
      setSuccessfulMessage(false);
      setIsLoading(true);

      emailjs
        .send(
          "service_9rdl8rv",
          "template_kvkdlav",
          templateParams,
          Keys.EMAIL_JS_KEY
        )
        .then(() => {
          setSuccessfulMessage(true);
          setFailedMessage(false);
          setIsLoading(false);
          setCaptchaResponse("");
          resetForm();
        })
        .catch((response) => {
          setFailedMessage(true);
          setSuccessfulMessage(false);
          setIsLoading(false);
          console.log(response);
        });
    }
  };

  return (
    <>
      <form className="contact_form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-messages">
        {successfulMessage && (
          <Alert severity="success" className="margin-bottom-20">
            <AlertTitle>Success!</AlertTitle>
            Your message has been sent, we will get back to you as soon as we can!
          </Alert>
        )}
        {failedMessage && (
          <Alert severity="error" className="margin-bottom-20">
            <AlertTitle>Error!</AlertTitle>
            There was an error, please try again.
          </Alert>
        )}
        </div>
        <div className="first">
          <ul>
            <li>
              <input
                {...register("name", { required: true })}
                type="text"
                placeholder="Name"
                onChange={(e) => setName(e.target.value)}
              />
              {errors.name && errors.name.type === "required" && (
                <span>Name is required</span>
              )}
            </li>

            <li>
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Please enter a valid email.",
                    },
                  },
                  { required: true }
                )}
                type="email"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {errors.email && <span role="alert">{errors.email.message}</span>}
            </li>

            <li>
              <textarea
                {...register("subject", { required: true })}
                placeholder="Message"
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              {errors.message && <span>Message is required.</span>}
            </li>

            <li>
              <Recaptcha
                sitekey={Keys.CAPTCHA_SITE_KEY}
                render="explicit"
                onloadCallback={recaptchaLoaded}
                verifyCallback={verifiedRecaptcha}
              />
            </li>
          </ul>
        </div>

        <div className="tokyo_tm_button">
          <button type="submit" className="white-fill-bg fill-black">
            Send Message 
            {isLoading && (
              <CircularProgress
                size="25"
              />
            )}
          </button>
        </div>
      </form>
    </>
  );
};

export default Contact;

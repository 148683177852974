import React, { useState } from "react";
import homeBuyingImg from "../assets/img/homebuying.jpg";
import coachingImg from "../assets/img/coaching.jpg";
import marketingImg from "../assets/img/marketing.jpg"

const NewsTwo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }

  return (
    <div className="shane_tm_section" id="services">
      <div className="shane_tm_news">
        <div className="container">
          <div className="shane_tm_title">
            <span>Skills &#38; Services</span>
            <h3>Additional Skills &#38; Services from Anthony Bellamy</h3>
          </div>
          {/* End shane_tm_title */}
          <div className="news_list">
            <ul>
              <li data-aos="fade-right" data-aos-duration="1200">
                <div className="list_inner">
                  <div className="image" onClick={toggleModalOne}>
                    <img src={homeBuyingImg} alt="thumb" />
                    <div
                      className="main home-buying-image"
                    ></div>
                  </div>
                  {/* End image */}

                  <div className="details">
                    <h3 className="title" onClick={toggleModalOne}>
                      Home Buying Assistance
                    </h3>
                    <p className="date">
                      First time home buyers can learn the step-by-step process of buying a new home from a professional realtor. 
                    </p>
                  </div>
                  {/* End details */}
                </div>
              </li>
              {/* End single blog */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <div className="list_inner">
                  <div className="image" onClick={toggleModalTwo}>
                    <img src={coachingImg} alt="thumb" />
                    <div
                      className="main coaching-image"
                    ></div>
                  </div>
                  {/* End image */}

                  <div className="details">
                    <h3 className="title" onClick={toggleModalTwo}>
                      Real Estate Coaching
                    </h3>
                    <p className="date">
                      Anthony specializes in teaching and coaching individuals who are interested in joining the real estate business.
                    </p>
                  </div>
                  {/* End details */}
                </div>
                {/* End list inner */}
              </li>
              {/* End single blog */}

              <li
                data-aos="fade-right"
                data-aos-duration="1200"
                data-aos-delay="300"
              >
                <div className="list_inner">
                  <div className="image" onClick={toggleModalThree}>
                    <img src={marketingImg} alt="thumb" />
                    <div
                      className="main marketing-image"
                    ></div>
                  </div>
                  {/* END IMAGE */}

                  <div className="details">
                    <h3 className="title" onClick={toggleModalThree}>
                      Real Estate Marketing
                    </h3>
                    <p className="date">
                      Learn from a professional realtor skilled in social media marketing.
                    </p>
                  </div>
                  {/* END DETAILS */}
                </div>
                {/* END LIST INNER */}
              </li>

              {/* End single blog */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsTwo;

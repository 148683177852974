import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { NavLink } from "react-router-dom";
import logoImg from "../assets/img/logotransparent.png";

const Header = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <>
            <div className="shane_tm_topbar">
                <div className={navbar ? "topbar_inner opened" : "topbar_inner"}>
                    <div className="logo">
                        <NavLink to="/">
                            <img src={logoImg} className="logo-img" alt="Logo" />
                        </NavLink>
                    </div>
                    <div className="menu">
                        <Scrollspy
                            className="anchor_nav"
                            items={["home", "about", "services", "contact"]}
                            currentClassName="current"
                            offset={-200}
                        >
                            <li>
                                <a href="#home">Home</a>
                            </li>
                            <li>
                                <a href="#about">About</a>
                            </li>
                            <li>
                                <a href="#services">Skills &#38; Services</a>
                            </li>
                            <li>
                                <a href="#contact">Contact</a>
                            </li>
                        </Scrollspy>
                    </div>
                </div>
            </div>
            {/* End shane_tm_topbar */}

            {/* Start shane mobile menu */}
            <div className="shane_tm_mobile_menu">
                <div className="topbar_inner">
                    <div className="container bigger">
                        <div className="topbar_in">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logoImg} alt="Logo" />
                                </NavLink>
                            </div>
                            <div className="my_trigger" onClick={handleClick}>
                                <div
                                    className={
                                        click
                                            ? "hamburger hamburger--collapse-r is-active"
                                            : "hamburger"
                                    }
                                >
                                    <div className="hamburger-box">
                                        <div className="hamburger-inner"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={click ? "dropdown active" : "dropdown"}>
                    <div className="container">
                        <span className="close_menu" onClick={handleClick}>
                            close
                        </span>
                        <div className="dropdown_inner">
                            <ul className="anchor_nav">
                                <li className="current">
                                    <a href="#home" onClick={handleClick}>
                                        Home
                                    </a>
                                </li>
                                <li>
                                    <a href="#about" onClick={handleClick}>
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="#services" onClick={handleClick}>
                                        Skills &#38; Service
                                    </a>
                                </li>
                                <li>
                                    <a href="#contact" onClick={handleClick}>
                                        Contact
                                    </a>
                                </li>
                            </ul>
                            {/* <div className="social-menu">
                                <Social />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Header;

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Home } from './components/Home';
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

import "../src/assets/scss/style.scss";
import "../src/custom.css";

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

    return (
      <div className="shane_tm_all_wrap">
        <ScrollToTop />
        <Router>
            <Switch>
                <Route exact path='/' component={Home} />
            </Switch>
        </Router>
      </div>
    );
};

export default App;

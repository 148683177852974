import React, { Component } from 'react';
import Header from "./Header.jsx";
import Slider from "./Slider.jsx";
import About from "./About.jsx";
// import Skills from "./Skills.jsx";
import Services from "./Services.jsx";
import CallToAction from "./CallToAction.jsx";
import Footer from "./Footer.jsx";

export class Home extends Component {
  static displayName = Home.name;

  render () {
    return (
        <div className="home-two">
            <Header />
            <Slider />
            <About />
            {/* <Skills /> */}
            <Services />
            <CallToAction />
            <Footer />
        </div>
    );
  }
}

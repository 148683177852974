import React from "react";
import bootstrap from 'bootstrap';
import headshot from "../assets/img/abheadshot.jpg";
import profile2 from "../assets/img/profile2.jpeg";
import profile3 from "../assets/img/profile3.jpeg";
import profile4 from "../assets/img/profile4.jpeg";

const Slider = () => {
  return (
    <>
      <div className="shane_tm_hero" id="home" data-style="two">
        <div className="background">
          {/* <div
            className="image headshot-image"
          ></div> */}
          <div id="carouselExampleSlidesOnly" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img src={headshot} className="d-block w-100" alt="headshot" />
              </div>
              <div className="carousel-item">
                <img src={profile2} className="d-block w-100" alt="profile2" />
              </div>
              <div className="carousel-item">
                <img src={profile3} className="d-block w-100" alt="profile3" />
              </div>
              <div className="carousel-item">
                <img src={profile4} className="d-block w-100" alt="profile4" />
              </div>
            </div>
          </div>
        </div>
        {/* End .background */}

        <div className="container">
          <div className="content">
            <div className="name_wrap">
              <h3>
                <span>Anthony</span> Bellamy &trade;<span className="overlay_effect"></span>
              </h3>
            </div>
            {/* End title */}

            <div className="job_wrap">
              <span className="job">
                Real Estate Agent &#38; Developer
                <span className="overlay_effect"></span>
              </span>
            </div>
            {/* End designation */}
          </div>
          {/* End content */}

          <div className="shane_tm_down loaded">
            <div className="line_wrapper">
              <div className="line"></div>
            </div>
          </div>
        </div>
        {/* End .container */}
      </div>
    </>
  );
};

export default Slider;

